"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formRegexpMapping = exports.ZIP_CODE_REGEXP = exports.ONLY_NUMBERS_AND_LETTERS = exports.ONLY_NUMBERS_ONE_HYPHEN_REGEXP = void 0;
const enum_1 = require("../enum");
exports.ONLY_NUMBERS_ONE_HYPHEN_REGEXP = /^\d+(-\d+)?$/;
exports.ONLY_NUMBERS_AND_LETTERS = /^[a-zA-Z0-9]+$/;
exports.ZIP_CODE_REGEXP = /^[0-9]*$/;
exports.formRegexpMapping = {
  [enum_1.FormRegexpNames.ONLY_NUMBERS_ONE_HYPHEN_REGEXP]: exports.ONLY_NUMBERS_ONE_HYPHEN_REGEXP,
  [enum_1.FormRegexpNames.ONLY_NUMBERS_AND_LETTERS]: exports.ONLY_NUMBERS_AND_LETTERS,
  [enum_1.FormRegexpNames.ZIP_CODE_REGEXP]: exports.ZIP_CODE_REGEXP
};
