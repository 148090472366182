"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stripName = stripName;
exports.getEnumKeyByValue = getEnumKeyByValue;
exports.arrayUnique = arrayUnique;
exports.arrayUniqueByKey = arrayUniqueByKey;
exports.deepCopy = deepCopy;
exports.areSameUnsorted = areSameUnsorted;
exports.isArrayIncluded = isArrayIncluded;
exports.arrayDiff = arrayDiff;
exports.arrayIntersection = arrayIntersection;
function stripName(str) {
  if (!str) return '';
  return str.trim().replace(/&/g, 'and').replace(/,/g, '_').replace(/\s+/g, '_').replace(/[^A-z0-9_-]/g, '').replace(/-/g, '_').replace(/_+/g, '_').replace(/[()]/g, '').toUpperCase();
}
function getEnumKeyByValue(enumObj, value) {
  for (const key of Object.keys(enumObj)) {
    if (enumObj[key] === value) {
      return key;
    }
  }
  return null;
}
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
function arrayUnique(ar) {
  return ar.filter(onlyUnique);
}
function onlyUniqueByKey(key) {
  return (value, index, self) => self.findIndex(item => item[key] === value[key]) === index;
}
function arrayUniqueByKey(ar, key) {
  return ar.filter(onlyUniqueByKey(key));
}
function deepCopy(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }
  const copy = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      copy[key] = deepCopy(obj[key]);
    }
  }
  return copy;
}
function areSameUnsorted(arrA, arrB) {
  if (arrB.length !== arrB.length) {
    return false;
  }
  const sortedArr1 = arrA.slice().sort();
  const sortedArr2 = arrB.slice().sort();
  for (let i = 0; i < sortedArr1.length; ++i) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }
  return true;
}
function isArrayIncluded(big, small) {
  return small.every(element => big.includes(element));
}
function arrayDiff(arrA, arrB) {
  return arrA.filter(element => !arrB.includes(element));
}
function arrayIntersection(arrA, arrB) {
  return arrA.filter(element => arrB.includes(element));
}
