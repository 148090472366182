"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MassAndVolumeUnits = exports.EnergyIntensity = exports.EnergyIntensityDistanceTraveled = exports.EnergyIntensityMonetaryUnits = exports.SolidUnitWithProductionVolume = exports.EnergyConsumptionUnit = exports.FuelConsumptionUnit = exports.MobileCombustionUnit = exports.StationaryCombustionUnit = exports.ConsumptionUnitMap = exports.ConsumptionUnitTypeMap = exports.EnergyUnitCategoryMap = exports.EnergyUnitCategory = exports.EnergyElectricUnit = exports.EnergyGasUnit = exports.EnergyLiquidUnit = exports.EnergySolidUnit = exports.HeatSteamUnit = exports.HeatSteamCoolingUnit = exports.HeatSteamHeatingUnit = exports.defaultUnits = exports.DensityInputUnit = exports.EnergyOutput = exports.EnergyUnit = exports.EnergyIntensityPerRevenue = exports.EnergyIntensityPerProduct = exports.EnergyIntensityPerEmployee = exports.EnergyIntensityPerFloorArea = exports.EnergyIntensityOptionalHours = exports.EnergyIntensityServicesProvided = exports.EnergyIntensityNumberOfEmployee = exports.EnergyIntensitySize = exports.EnergyIntensityProductionVolume = exports.EnergySteamConsumptionUnit = exports.EnergyCoolingConsumptionUnit = exports.EnergyHeatingConsumptionUnit = exports.FuelLiquidUnit = exports.VehicleUnit = exports.Currency = exports.DistanceUnit = exports.HeatUnit = exports.ElectricPowerUnit = exports.ElectricUnit = exports.GasUnit = exports.LiquidUnit = exports.SolidUnit = exports.ConsumptionType = void 0;
exports.unitToUnitCode = unitToUnitCode;
exports.codeToUnit = codeToUnit;
var ConsumptionType;
(function (ConsumptionType) {
  ConsumptionType["FUEL_SOLID"] = "SOLID";
  ConsumptionType["FUEL_LIQUID"] = "LIQUID";
  ConsumptionType["FUEL_GAS"] = "GAS";
  ConsumptionType["FUEL_ELECTRIC"] = "ELECTRIC";
  ConsumptionType["ELECTRICITY"] = "ELECTRICITY";
  ConsumptionType["HEAT_STEAM_GAS"] = "HEAT_STEAM_GAS";
  ConsumptionType["DISTANCE"] = "DISTANCE";
  ConsumptionType["CURRENCY"] = "CURRENCY";
  ConsumptionType["VEHICLE"] = "VEHICLE";
  ConsumptionType["ENERGY"] = "ENERGY";
  ConsumptionType["HEATING"] = "HEATING";
  ConsumptionType["COOLING"] = "COOLING";
  ConsumptionType["STEAM"] = "STEAM";
})(ConsumptionType || (exports.ConsumptionType = ConsumptionType = {}));
var SolidUnit;
(function (SolidUnit) {
  SolidUnit["POUND"] = "lb";
  SolidUnit["SHORT_TON"] = "ST";
  SolidUnit["METRIC_TON"] = "MT";
  SolidUnit["LONG_TON"] = "LT";
  SolidUnit["KG"] = "kg";
  SolidUnit["GRAM"] = "g";
})(SolidUnit || (exports.SolidUnit = SolidUnit = {}));
var LiquidUnit;
(function (LiquidUnit) {
  LiquidUnit["GALLON"] = "gal";
  LiquidUnit["LITTER"] = "L";
})(LiquidUnit || (exports.LiquidUnit = LiquidUnit = {}));
var GasUnit;
(function (GasUnit) {
  GasUnit["STANDARD_CUBIC_FOOT"] = "scf";
})(GasUnit || (exports.GasUnit = GasUnit = {}));
var ElectricUnit;
(function (ElectricUnit) {
  ElectricUnit["KILOWATT_HOUR"] = "kWh";
})(ElectricUnit || (exports.ElectricUnit = ElectricUnit = {}));
var ElectricPowerUnit;
(function (ElectricPowerUnit) {
  ElectricPowerUnit["KILOWATT_HOUR"] = "kWh";
  ElectricPowerUnit["MEGAWATT_HOUR"] = "MWh";
  ElectricPowerUnit["GIGAWATT_HOUR"] = "GWh";
})(ElectricPowerUnit || (exports.ElectricPowerUnit = ElectricPowerUnit = {}));
var HeatUnit;
(function (HeatUnit) {
  HeatUnit["MEGAJOULE"] = "MJ";
  HeatUnit["GIGAJOULE"] = "GJ";
  HeatUnit["MMBTU"] = "MMBtu";
})(HeatUnit || (exports.HeatUnit = HeatUnit = {}));
var DistanceUnit;
(function (DistanceUnit) {
  DistanceUnit["KILOMETER"] = "km";
  DistanceUnit["MILE"] = "mi";
  DistanceUnit["NAUTICAL_MILE"] = "NM";
})(DistanceUnit || (exports.DistanceUnit = DistanceUnit = {}));
var Currency;
(function (Currency) {
  Currency["USD"] = "USD";
  Currency["INR"] = "INR";
  Currency["EUR"] = "EUR";
  Currency["GBP"] = "GBP";
  Currency["JPY"] = "JPY";
  Currency["AUD"] = "AUD";
  Currency["CNY"] = "CNY";
})(Currency || (exports.Currency = Currency = {}));
var VehicleUnit;
(function (VehicleUnit) {
  VehicleUnit["KILOMETER_PER_LITTER"] = "km/l";
  VehicleUnit["MILE_PER_GALLON"] = "MPG";
})(VehicleUnit || (exports.VehicleUnit = VehicleUnit = {}));
var FuelLiquidUnit;
(function (FuelLiquidUnit) {
  FuelLiquidUnit["CUBIC_METER"] = "m3";
})(FuelLiquidUnit || (exports.FuelLiquidUnit = FuelLiquidUnit = {}));
exports.EnergyHeatingConsumptionUnit = {
  BRITISH_THERMAL_UNIT: 'BTU',
  THERM: 'thm',
  MILLION_BTU: HeatUnit.MMBTU
};
var EnergyCoolingConsumptionUnit;
(function (EnergyCoolingConsumptionUnit) {
  EnergyCoolingConsumptionUnit["TON_HOURS"] = "ton-hr";
})(EnergyCoolingConsumptionUnit || (exports.EnergyCoolingConsumptionUnit = EnergyCoolingConsumptionUnit = {}));
exports.EnergySteamConsumptionUnit = {
  POUND_OF_STEAM: 'lbs',
  KILOGRAM_OF_STEAM: SolidUnit.KG
};
var EnergyIntensityProductionVolume;
(function (EnergyIntensityProductionVolume) {
  EnergyIntensityProductionVolume["UNITS"] = "UNITS";
})(EnergyIntensityProductionVolume || (exports.EnergyIntensityProductionVolume = EnergyIntensityProductionVolume = {}));
var EnergyIntensitySize;
(function (EnergyIntensitySize) {
  EnergyIntensitySize["SQUARE_METERS"] = "m3";
  EnergyIntensitySize["SQUARE_FEET"] = "ft2";
})(EnergyIntensitySize || (exports.EnergyIntensitySize = EnergyIntensitySize = {}));
var EnergyIntensityNumberOfEmployee;
(function (EnergyIntensityNumberOfEmployee) {
  EnergyIntensityNumberOfEmployee["EMPLOYEES"] = "EMPLOYEES";
})(EnergyIntensityNumberOfEmployee || (exports.EnergyIntensityNumberOfEmployee = EnergyIntensityNumberOfEmployee = {}));
var EnergyIntensityServicesProvided;
(function (EnergyIntensityServicesProvided) {
  EnergyIntensityServicesProvided["SERVICES_HOURS"] = "SERVICES_HOURS";
})(EnergyIntensityServicesProvided || (exports.EnergyIntensityServicesProvided = EnergyIntensityServicesProvided = {}));
var EnergyIntensityOptionalHours;
(function (EnergyIntensityOptionalHours) {
  EnergyIntensityOptionalHours["HOURS"] = "h";
})(EnergyIntensityOptionalHours || (exports.EnergyIntensityOptionalHours = EnergyIntensityOptionalHours = {}));
var EnergyIntensityPerFloorArea;
(function (EnergyIntensityPerFloorArea) {
  EnergyIntensityPerFloorArea["KILOJOULES_PER_SQUARE_METER"] = "kJ/m\u00B2";
})(EnergyIntensityPerFloorArea || (exports.EnergyIntensityPerFloorArea = EnergyIntensityPerFloorArea = {}));
var EnergyIntensityPerEmployee;
(function (EnergyIntensityPerEmployee) {
  EnergyIntensityPerEmployee["KILOJOULES_PER_EMPLOYEE"] = "kJ/Employee";
})(EnergyIntensityPerEmployee || (exports.EnergyIntensityPerEmployee = EnergyIntensityPerEmployee = {}));
var EnergyIntensityPerProduct;
(function (EnergyIntensityPerProduct) {
  EnergyIntensityPerProduct["KILOJOULES_PER_UNIT"] = "kJ/Unit";
})(EnergyIntensityPerProduct || (exports.EnergyIntensityPerProduct = EnergyIntensityPerProduct = {}));
var EnergyIntensityPerRevenue;
(function (EnergyIntensityPerRevenue) {
  EnergyIntensityPerRevenue["KILOJOULES_PER_DOLLAR"] = "kJ/Dollar";
})(EnergyIntensityPerRevenue || (exports.EnergyIntensityPerRevenue = EnergyIntensityPerRevenue = {}));
var EnergyUnit;
(function (EnergyUnit) {
  EnergyUnit["KILOJOULE"] = "kJ";
  EnergyUnit["MEGAJOULE"] = "MJ";
  EnergyUnit["GIGAJOULE"] = "GJ";
  EnergyUnit["CALORIE"] = "cal";
  EnergyUnit["KILOCALORIE"] = "kcal";
  EnergyUnit["WATTHOUR"] = "Wh";
  EnergyUnit["KILOWATT_HOUR"] = "kWh";
  EnergyUnit["MEGAWATT_HOUR"] = "MWh";
  EnergyUnit["GIGAWATT_HOUR"] = "GWh";
  EnergyUnit["BRITISH_THERMAL_UNIT"] = "BTU";
  EnergyUnit["THERM"] = "thm";
  EnergyUnit["MMBTU"] = "MMBtu";
  EnergyUnit["TON_HOURS"] = "ton-hr";
  EnergyUnit["POUND_OF_STEAM"] = "lbs";
  EnergyUnit["KG"] = "kg";
  EnergyUnit["JOULE"] = "J";
})(EnergyUnit || (exports.EnergyUnit = EnergyUnit = {}));
exports.EnergyOutput = {
  KILOJOULE: EnergyUnit.KILOJOULE,
  MEGAJOULE: EnergyUnit.MEGAJOULE,
  GIGAJOULE: EnergyUnit.GIGAJOULE,
  JOULE: EnergyUnit.JOULE
};
exports.DensityInputUnit = {
  GALLON: LiquidUnit.GALLON,
  LITTER: LiquidUnit.LITTER,
  CUBIC_METER: FuelLiquidUnit.CUBIC_METER
};
exports.defaultUnits = {
  SOLID: 'ST',
  LIQUID: 'gal',
  GAS: 'scf',
  ELECTRIC: 'kWh',
  ELECTRICITY: 'kWh',
  HEAT_STEAM_GAS: 'MMBtu',
  DISTANCE: 'mi',
  CURRENCY: 'USD'
};
exports.HeatSteamHeatingUnit = {
  BRITISH_THERMAL_UNIT: EnergyUnit.BRITISH_THERMAL_UNIT,
  THERM: EnergyUnit.THERM,
  CUBIC_FEET: 'ft3',
  KILOWATT_HOUR: EnergyUnit.KILOWATT_HOUR,
  GALLON: LiquidUnit.GALLON,
  LITTER: LiquidUnit.LITTER,
  JOULE: EnergyUnit.JOULE,
  MEGAJOULE: EnergyUnit.MEGAJOULE,
  GIGAJOULE: EnergyUnit.GIGAJOULE
};
exports.HeatSteamCoolingUnit = {
  TON: 'TR',
  KILOWATT_HOUR: EnergyUnit.KILOWATT_HOUR,
  BRITISH_THERMAL_UNIT: EnergyUnit.BRITISH_THERMAL_UNIT,
  MEGAJOULE: EnergyUnit.MEGAJOULE
};
exports.HeatSteamUnit = {
  POUND: SolidUnit.POUND,
  KG: SolidUnit.KG,
  BRITISH_THERMAL_UNIT: EnergyUnit.BRITISH_THERMAL_UNIT,
  KILOWATT_HOUR: EnergyUnit.KILOWATT_HOUR,
  MEGAJOULE: EnergyUnit.MEGAJOULE,
  GIGAJOULE: EnergyUnit.GIGAJOULE
};
exports.EnergySolidUnit = {
  ...SolidUnit
};
exports.EnergyLiquidUnit = {
  ...SolidUnit,
  ...LiquidUnit,
  KILLO_LITTER: 'KL'
};
exports.EnergyGasUnit = {
  ...SolidUnit,
  ...LiquidUnit,
  ...GasUnit,
  ...FuelLiquidUnit,
  STANDARD_CUBIC_METER: 'sm3'
};
exports.EnergyElectricUnit = {
  ...ElectricPowerUnit
};
var EnergyUnitCategory;
(function (EnergyUnitCategory) {
  EnergyUnitCategory["SOLID"] = "SOLID";
  EnergyUnitCategory["LIQUID"] = "LIQUID";
  EnergyUnitCategory["GAS"] = "GAS";
  EnergyUnitCategory["ELECTRIC"] = "ELECTRIC";
})(EnergyUnitCategory || (exports.EnergyUnitCategory = EnergyUnitCategory = {}));
exports.EnergyUnitCategoryMap = {
  [exports.EnergySolidUnit.POUND]: EnergyUnitCategory.SOLID,
  [exports.EnergySolidUnit.SHORT_TON]: EnergyUnitCategory.SOLID,
  [exports.EnergySolidUnit.METRIC_TON]: EnergyUnitCategory.SOLID,
  [exports.EnergySolidUnit.LONG_TON]: EnergyUnitCategory.SOLID,
  [exports.EnergySolidUnit.KG]: EnergyUnitCategory.SOLID,
  [exports.EnergySolidUnit.GRAM]: EnergyUnitCategory.SOLID,
  [exports.EnergyLiquidUnit.GALLON]: EnergyUnitCategory.LIQUID,
  [exports.EnergyLiquidUnit.LITTER]: EnergyUnitCategory.LIQUID,
  [exports.EnergyLiquidUnit.KILLO_LITTER]: EnergyUnitCategory.LIQUID,
  [exports.EnergyGasUnit.STANDARD_CUBIC_FOOT]: EnergyUnitCategory.GAS,
  [exports.EnergyGasUnit.STANDARD_CUBIC_METER]: EnergyUnitCategory.GAS,
  [exports.EnergyGasUnit.CUBIC_METER]: EnergyUnitCategory.GAS,
  [exports.EnergyElectricUnit.KILOWATT_HOUR]: EnergyUnitCategory.ELECTRIC,
  [exports.EnergyElectricUnit.MEGAWATT_HOUR]: EnergyUnitCategory.ELECTRIC,
  [exports.EnergyElectricUnit.GIGAWATT_HOUR]: EnergyUnitCategory.ELECTRIC
};
exports.ConsumptionUnitTypeMap = {
  [ConsumptionType.FUEL_SOLID]: SolidUnit,
  [ConsumptionType.FUEL_LIQUID]: LiquidUnit,
  [ConsumptionType.FUEL_GAS]: GasUnit,
  [ConsumptionType.FUEL_ELECTRIC]: ElectricUnit,
  [ConsumptionType.ELECTRICITY]: ElectricPowerUnit,
  [ConsumptionType.HEAT_STEAM_GAS]: HeatUnit,
  [ConsumptionType.DISTANCE]: DistanceUnit,
  [ConsumptionType.CURRENCY]: Currency,
  [ConsumptionType.VEHICLE]: VehicleUnit,
  [ConsumptionType.ENERGY]: EnergyUnit,
  [ConsumptionType.HEATING]: exports.HeatSteamHeatingUnit,
  [ConsumptionType.COOLING]: exports.HeatSteamCoolingUnit,
  [ConsumptionType.STEAM]: exports.HeatSteamUnit
};
function typeToUnits() {
  const consumptionUnitMap = {};
  for (const [consumptionType, unitEnum] of Object.entries(exports.ConsumptionUnitTypeMap)) {
    consumptionUnitMap[consumptionType] = Object.values(unitEnum);
  }
  return consumptionUnitMap;
}
exports.ConsumptionUnitMap = typeToUnits();
function unitToUnitCode(ct, value) {
  for (const [unitCode, unitEnum] of Object.entries(exports.ConsumptionUnitTypeMap[ct])) {
    if (unitEnum === value) {
      return unitCode;
    }
  }
  return null;
}
function codeToUnit(ct, code) {
  for (const [unitCode, unitEnum] of Object.entries(exports.ConsumptionUnitTypeMap[ct])) {
    if (unitCode === code) {
      return unitEnum;
    }
  }
  return null;
}
exports.StationaryCombustionUnit = {
  ...SolidUnit,
  ...LiquidUnit,
  ...GasUnit
};
exports.MobileCombustionUnit = {
  ...DistanceUnit,
  ...LiquidUnit,
  ...GasUnit,
  ...ElectricPowerUnit
};
exports.FuelConsumptionUnit = {
  ...exports.EnergySolidUnit,
  ...exports.EnergyLiquidUnit,
  ...exports.EnergyElectricUnit,
  ...exports.EnergyGasUnit
};
exports.EnergyConsumptionUnit = {
  ...ElectricPowerUnit,
  ...exports.EnergyHeatingConsumptionUnit,
  ...EnergyCoolingConsumptionUnit,
  ...exports.EnergySteamConsumptionUnit,
  ...SolidUnit
};
exports.SolidUnitWithProductionVolume = {
  ...SolidUnit,
  ...LiquidUnit,
  ...EnergyIntensityProductionVolume
};
exports.EnergyIntensityMonetaryUnits = {
  ...Currency
};
exports.EnergyIntensityDistanceTraveled = {
  ...DistanceUnit
};
exports.EnergyIntensity = {
  ...exports.SolidUnitWithProductionVolume,
  ...EnergyIntensitySize,
  ...EnergyIntensityNumberOfEmployee,
  ...exports.EnergyIntensityMonetaryUnits,
  ...EnergyIntensityServicesProvided,
  ...exports.EnergyIntensityDistanceTraveled,
  ...EnergyIntensityOptionalHours,
  ...EnergyIntensityPerFloorArea,
  ...EnergyIntensityPerEmployee,
  ...EnergyIntensityPerProduct,
  ...EnergyIntensityPerRevenue
};
exports.MassAndVolumeUnits = {
  ...SolidUnit,
  ...LiquidUnit,
  ...GasUnit
};
