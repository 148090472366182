"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StorageDirectory = exports.StorageProvider = void 0;
var StorageProvider;
(function (StorageProvider) {
  StorageProvider["GCP"] = "GCP";
})(StorageProvider || (exports.StorageProvider = StorageProvider = {}));
var StorageDirectory;
(function (StorageDirectory) {
  StorageDirectory["ACTIVITIES"] = "activities";
  StorageDirectory["AVATARS"] = "avatars";
})(StorageDirectory || (exports.StorageDirectory = StorageDirectory = {}));
